import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import slugify from 'react-slugify';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout/layout';
import SEO from '../../components/seo';
import Title from '../../components/layout/title';
import { SuperButton } from '../../components/layout/buttons';
import { useEffect } from 'react';

const TalentPage = ({ location, data }) => {
  useEffect(() => {
    const checkJQuery = setInterval(() => {
      if (window.jQuery && window.jQuery.fn.DataTable) {
        window.jQuery(document).ready(function () {
          debugger;
          window
            .jQuery('table.dataTable')
            .DataTable({ scrollX: true, fixedColumns: true, fixedHeader: true });
          clearInterval(checkJQuery);
        });
      }
    }, 100);
  }, []);
  const { priorityCommissions = {} } = data;
  const previewImage = data.previewImage.childImageSharp.resize;
  const previewImageInline = getImage(data.previewImage.childImageSharp);
  const cityWide = getImage(data.CityWide.childImageSharp);
  const twinPeaks = getImage(data.TwinPeaks.childImageSharp);

  const interestForm = 'https://airtable.com/appjZr9RICbWxEKfv/pagS2UpMpKAy4vski/form';
  const joinButton = (
    <div className="w-fit mx-auto">
      <SuperButton
        href={interestForm}
        className="!inline-block !normal-case !rounded-md"
        color="blue"
      >
        Submit your interest & qualifications for GrowSF Talent
      </SuperButton>
    </div>
  );

  return (
    <Layout>
      <SEO
        title="GrowSF Talent"
        description="Run for something."
        pathname={location.pathname}
        image={previewImage}
      />
      <div className="w-full">
        <div className="max-w-7xl mx-auto px-5 sm:px-6 lg:px-8 mb-6 sm:mb-16">
          <Title title="GrowSF Talent">
            <strong>San Francisco Needs You!</strong>
          </Title>
          <div className="flex flex-col-reverse lg:flex-row items-start gap-6 sm:gap-12">
            <div
              className="lg:w-2/5 m-auto voters prose sm:prose-sm md:prose-base lg:prose-lg"
              data-js-body
            >
              <p>
                San Francisco deserves great leaders who get great outcomes. We need more
                commonsense, results-oriented people from a wide variety of backgrounds and
                industries participating in local government. To do this,{' '}
                <strong>we need to significantly expand the talent pipeline</strong>. And, we need
                to make it easier for qualified people to find ways to contribute.
              </p>
              <p>
                If you're a San Franciscan looking to volunteer, join a commission, work in City
                Hall, or run for office yourself - we can guide you to the right roles, connect you
                to the right people, and even endorse you if you run.
              </p>
              <p>
                If you're an elected official looking to hire or appoint exceptional talent, we can
                help you find the right person for the role.
              </p>
              <p>
                The future of our city depends on who steps up to lead it -{' '}
                <strong>GrowSF Talent</strong> is here to help.
              </p>
            </div>
            <GatsbyImage
              image={previewImageInline}
              alt="San Francisco Needs You! Join GrowSF Talent."
              className="max-w-xl lg:max-w-5xl lg:w-3/5 mx-auto"
            />
          </div>
        </div>
        <div className="w-full mx-auto bg-gradient-to-tr from-brand-blue-5 via-brand-blue-5 to-brand-green-4 p-6 sm:p-12">
          <div className="max-w-7xl mx-auto">
            <div className="text-center pb-6 sm:pb-8 border-b border-slate-100/25">
              <h2 className="text-2xl sm:text-3xl font-black text-slate-100">For Candidates</h2>
              <p className="text-lg sm:text-xl text-slate-300">GrowSF Talent is here to help</p>
            </div>
            <div className="grid md:grid-cols-2 sm:gap-12 lg:gap-12 py-8">
              <div className="mb-6 sm:mb-0">
                <h3 className="text-xl font-bold text-slate-100 mb-4">Run for office</h3>
                <p className="leading-normal text-slate-300">
                  In every election cycle, we want the best possible candidates running for office.
                  We’re looking for people with high integrity, a service mindset, strong policy
                  acumen and GrowSF alignment, and the ability to drive relentlessly towards the
                  outcomes voters want. If you plan to run for an elected position, and are
                  interested in GrowSF’s endorsement,{' '}
                  <Link className="text-brand-green-3 underline" href={interestForm}>
                    submit your interest
                  </Link>
                  .
                </p>
              </div>
              <div className="mb-6 sm:mb-0">
                <h3 className="text-xl font-bold text-slate-100 mb-4">Join a Commission</h3>
                <p className="leading-normal text-slate-300">
                  You don’t have to run for office to have a meaningful impact on our city. Joining
                  a Board, Commission, or Task Force is a great way to lend your time and talent.
                  These groups range in time commitment. Some are paid, but most are unpaid, and
                  some make you eligible for City healthcare benefits. To learn more about the
                  commissions, what vacancies exist, and how to apply for them - check out our{' '}
                  <Link className="text-brand-green-3 underline" to="/talent/tracker">
                    Boards, Commissions, and Task Force tracker
                  </Link>
                  .
                </p>
              </div>
              <div className="mb-6 sm:mb-0">
                <h3 className="text-xl font-bold text-slate-100 mb-4">
                  Work for an elected official
                </h3>
                <p className="leading-normal text-slate-300">
                  Great officials are enabled by great staff — strategic thinkers, policy experts,
                  and operational tempo setters who execute with speed and precision. We work with
                  elected officials to help them get great talent. If you want to work for one of
                  them,{' '}
                  <Link className="text-brand-green-3 underline" href={interestForm}>
                    let us know
                  </Link>
                  .
                </p>
              </div>
              <div className="mb-6 sm:mb-0">
                <h3 className="text-xl font-bold text-slate-100 mb-4">Join the Civil Grand Jury</h3>
                <p className="leading-normal text-slate-300">
                  Want to investigate the operations of San Francisco? Joining the Civil Grand Jury
                  is a great way to help shine light on public business. You need to commit to a
                  minimum of 10 hours per week. The deadline for this year is May 2, 2025. Check out
                  more about the Civil Grand Jury and{' '}
                  <OutboundLink
                    className="text-brand-green-3 underline"
                    href="https://www.sf.gov/apply-join-civil-grand-jury"
                  >
                    apply for the 2025-2026 year here
                  </OutboundLink>
                  .
                </p>
              </div>
            </div>
            {joinButton}
          </div>
        </div>
        <div className="mt-12 bg-gradient-to-tr from-brand-green-5  to-brand-yellow-5 p-6 sm:p-12">
          <div className="max-w-7xl mx-auto">
            <div className="text-center pb-6 sm:pb-8 border-b border-slate-100/25">
              <h2 className="text-2xl sm:text-3xl font-black text-slate-100">
                <Link to="/talent/tracker/">Commission tracker</Link>
              </h2>
              <p className="text-lg sm:text-xl text-slate-300">
                Here's just a few commissions that may need great candidates
              </p>
            </div>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 sm:gap-12 lg:gap-12 py-8">
              {priorityCommissions.nodes.length > 0 &&
                priorityCommissions.nodes.map((commission) => (
                  <Link to={`/talent/tracker/#${slugify(commission.Committee_Name)}`}>
                    <div key={commission.Committee_Name} className="mb-6 sm:mb-0">
                      <h4 className="text-xl font-bold text-slate-100 mb-4">
                        {commission.Committee_Name}
                      </h4>
                      <p className="text-slate-100 line-clamp-3 mb-4">
                        {commission.Simplifed_Committee_Description}
                      </p>
                      <p className="w-fit block rounded-md bg-brand-blue-4 p-2 text-slate-100 line-clamp-3">
                        Learn more &raquo;
                      </p>
                    </div>
                  </Link>
                ))}
              <Link className="text-xl font-bold text-slate-100" to="/talent/tracker">
                View all commissions &raquo;
              </Link>
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto px-5 sm:px-6 lg:px-8 mt-12 sm:mt-24">
          <div className="mx-auto grid sm:grid-cols-2 sm:gap-12 lg:gap-12 py-8">
            <div>
              <h3 className="text-xl font-bold mb-4 text-brand-blue-4">
                For elected officials and hiring managers
              </h3>
              <p className="text-gray-700 leading-normal mb-6">
                Looking to grow your team, or make critical appointments, but having a hard time
                finding great people? Our team can help you find the right person for the role.{' '}
                <a
                  className="text-brand-blue-3 hover:no-underline underline"
                  href="mailto:talent@growsf.org"
                >
                  Email us
                </a>
                .
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4 text-brand-blue-4">Get in touch</h3>
              <p className="text-gray-700 leading-normal">
                If you have ideas about how to get more great leaders into government,{' '}
                <a
                  className="text-brand-blue-3 hover:no-underline underline"
                  href="mailto:talent@growsf.org"
                >
                  email us
                </a>
                !
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TalentPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    priorityCommissions: allCommitteesCsv(
      filter: { Priority: { eq: "P1" }, Presumed_Active_Inactive: { ne: "Inactive" } }
      sort: { fields: Committee_Name }
    ) {
      nodes {
        Committee_Name
        Category
        Has_Vacancy
        Simplifed_Committee_Description
        Decision_making___Advisory
        Eligible_for_Health_Benefits
        Eligible_for_Compensation
        Form_700_Filing_Requirement
        Required_Meetings_Per_Year
        Website
      }
    }
    CityWide: file(relativePath: { eq: "talent/iStock-1206567306.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    TwinPeaks: file(relativePath: { eq: "talent/iStock-1308114760.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    previewImage: file(relativePath: { eq: "talent/growsf-talent.png" }) {
      id
      childImageSharp {
        gatsbyImageData
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`;
